<template>
  <div class="BGS">
    <div class="user_login">
      <div class="user_login_top">用户登录</div>
      <div class="wrap-input100 validate-input m-b-23">
        <span class="label-input100">用户名</span>
        <input class="input100" type="text" v-model="username" @keyup.enter="infosub()" name="username" placeholder="请输入用户名" autocomplete="off">
        <span class="focus-input100" data-symbol=""></span>
        <img src="../assets/img/yonghu_ic.png" alt="">
      </div>
      <div class="wrap-input100 validate-input m-b-23">
        <span class="label-input100">密码</span>
        <input class="input100" type="password" v-model="password" @keyup.enter="infosub()" name="userpass" placeholder="请输入密码" autocomplete="off">
        <span class="focus-input100" data-symbol=""></span>
        <img src="../assets/img/suozi_ic.png" alt="">
      </div>
      <div class="container-login100-form-btn">
        <div class="wrap-login100-form-btn">
          <div class="login100-form-bgbtn"></div>
          <button class="login100-form-btn" @click="infosub">登 录</button>
        </div>
      </div>
      <div class="Go_reg" @click="toReg">
      去注册
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
import Axios from "axios";
export default {
     metaInfo(){
     return{
        title: '用户登录',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      username:'',//  用户名
      password:'',    //密码
    };
  },
  created() {
    // layui.use(["layer", "element", "carousel"], function () {
    //   var layer = layui.layer;
    //   var element = layui.element;
    //   var carousel = layui.carousel;
    // });

  },
  methods: {
    success(m) {
      layer.msg(m, { icon: 1 });
    },
    error(m) {
      layer.msg(m, { icon: 0 });
    },
    infosub(){
      //console.log(this.username)
       //console.log(this.password)
       if(!this.username){
        // this.error("用户名不能空");
        this.$message.error('用户名不能空');
        return false;
       }else{
        if(!this.password){
        // this.error("密码不能空");
        this.$message.error('密码不能空');
        return false;
       }else{
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/login",
       params: {
          username:this.username,
          password:md5(this.password),
        },
      }).then((res) => {
        //console.log(res);
        let e=res.data;
        if(e.code == 200){
        localStorage.setItem("user_information",JSON.stringify(e.data));
       localStorage.setItem("token",e.data.token);
       localStorage.setItem("nickname",e.data.nickname);
       localStorage.setItem("headimgurl",e.data.headimgurl);
       localStorage.setItem("uid",e.data.id);
       localStorage.setItem("is_brand_user",e.data.is_brand_user);
       localStorage.setItem("gettime",JSON.stringify(new Date().getTime()));
         this.$message.success(res.data.msg);
          setTimeout("location.href='/'",1000);
        }else{
            this.$message.error(res.data.msg);
        }
      });
       }
       }
    },
    toReg(){
		 this.$router.push('/register')
	 },
  },
};
</script>

<style scoped>
.BGS {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://club.qudong.com/static/index/login/images/bg-01.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.user_login {
  width: 400px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 65px 55px 54px 55px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.user_login_top {
  display: block;
  font-family: "Microsoft Yahei";
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}
.wrap-input100 {
  width: 100%;
  margin-top: 25px;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}
.wrap-input100 img {
  position: absolute;
  top: 40px;
  left: 10px;
  width: 24px;
  height: 22px;
}

.label-input100 {
  font-family: "Microsoft Yahei";
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
  padding-left: 7px;
}

.input100 {
  font-family: "Microsoft Yahei";
  font-size: 16px;
  color: #333333;
  border: none;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 55px;
  background: transparent;
  padding: 0 7px 0 43px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #adadad;
  font-size: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #7f7f7f;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #a64bf4;
}

.has-val.input100 + .focus-input100::after {
  color: #a64bf4;
}

/* 按钮 */
.container-login100-form-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}
.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 5px 30px 0px rgb(3 216 222 / 20%);
  -moz-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -webkit-box-shadow: 0 5px 30px 0px rgb(3 216 222 / 20%);
  -o-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -ms-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
}
.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: linear-gradient(to right, #00dbde, #fc00ff, #00dbde, #fc00ff);
  top: 0;
  left: -100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.login100-form-btn {
  font-family: "Microsoft Yahei";
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
}
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}
.Go_reg{
  margin-top:30px;
  cursor:pointer;
  text-align:center;

}
</style>